import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'pbb-svg-icon',
  templateUrl: './pbb-svg-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./pbb-svg-icon.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class PbbSvgIconComponent {
  @Input() icon!: string;
  @Input() width!: string;
  @Input() height!: string;
  @Input() title!:string;
  @HostBinding('attr.aria-hidden') ariaHidden = 'true';
}
